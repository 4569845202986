import { Injectable } from '@angular/core';

import {ToastController,LoadingController,Platform,MenuController,AlertController} from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})

export  class UtilService {

 public loading: any;
 public isLoading = false;
  constructor( public loadingCtrl: LoadingController,
  	           public toastController : ToastController,
  	           private alertCtrl: AlertController ) 
  { 
  }



validateEmail(data) {
    if( /(.+)@(.+){2,}\.(.+){2,}/.test(data) ){
      return {
        isValid: true,
        message: ''
      };
    } else {
       return {
          isValid: false,
          message: 'Email address is required'
       }
    }
}  

  async showLoader1(message:string)
  {
    this.loading = await this.loadingCtrl.create({
      message: message,
    });
    await this.loading.present();
    //this.loading.dismiss();

  } 

  async showLoader(message:string) {
    this.isLoading = true;
    return await this.loadingCtrl.create({
      // duration: 5000,
      message: message,
      mode:"ios",
    }).then(a => {
      a.present().then(() => {
        //console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('Qubit Team'));
        }
      });
    });
  }

  async dismiss() {
    this.isLoading = false;
    return await this.loadingCtrl.dismiss().then(() => console.log('Qubit Team'));
  }

  async dismiss1(){
    this.checkAndCloseLoader();
	
    // sometimes there's delay in finding the loader. so check if the loader is closed after one second. if not closed proceed to close again
      setTimeout(() => this.checkAndCloseLoader(), 1000);
    
  }

 async checkAndCloseLoader() {
    // Use getTop function to find the loader and dismiss only if loader is present.
    const loader = await this.loadingCtrl.getTop();
    // if loader present then dismiss
     if(loader !== undefined) { 
      this.loadingCtrl.dismiss();
       //await  loader.dismiss(); // this.loadingCtrl.dismiss();
       this.loading.dismiss();
     }
     //this.loadingCtrl.dismiss();
   }


  async presentToast(message,duration,position) {
    const toast = await this.toastController.create({
      message: message,
      position : position,
      duration: duration,
      mode:"ios",
      cssClass: `   --background:white !important;
                    --color:black !important;
                `
    });
    //this.loading.dismiss();
    toast.present();
  } 

  async presentAlertGeneric(message,title) {
    let alert = await this.alertCtrl.create({
      //enableBackdropDismiss: false,
      header: title,
      message: message,
      mode:'ios',
      buttons: ['Aceptar'],
      cssClass: `.alert-message {
                      text-align: justify;
                    } 
                `
    });
    await alert.present();
  }

  async presentAlertError(message) {
    let alert = await this.alertCtrl.create({
      //enableBackdropDismiss: false,
      header: 'Lo Sentimos',
      message: message,
      mode:'ios',
      buttons: ['Aceptar'],
      cssClass: `.alert-message {
                      text-align: justify;
                    } 
                `
    });
    await alert.present();
  }

  /* Mensaje de alerta. */
  async presentAlertOK(message) {
    let alert = await this.alertCtrl.create({
      //enableBackdropDismiss: false,
      header: 'En hora buena',
      message: message,
      mode:'ios', 
      buttons: ['Aceptar'],
      cssClass: 'alertCustomCss'
    });
    await alert.present();
  }

getRandomNumber()
  {
      var number = "1";
      for (var i = 0; i < 8; i++)
      {
          var part = Math.round(Math.random() * 255).toString(16);
          number += (part.length > 1) ? part : "0" + part;
      }
      return number;
  } 


}
