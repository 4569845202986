import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { CommonModule } from "@angular/common";
//@NgModule({  imports: [ CommonModule]})

import { AppComponent } from './app.component';
//import { ContentDrawer } from '../app/components/content-drawer/content-drawer';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule} from '@angular/common/http';

/* Owner Implementation Services */
import { HttpService } from './services/connection/httpservices.service';
import { AuthService } from './api/auth.service';
import { AuthGuard } from './services/auth.guard'
import { UtilService } from  './services/tools/tools.service'


import { Storage }  from '@capacitor/storage'; 
//import { SettingsService } from './services/settings/settings.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

  /**
   * The Settings provider takes a set of default settings for your app.
   *
   * You can add new settings options at any time. Once the settings are saved,
   * these values will not overwrite the saved values (this can be done manually if desired).
   */
/*

export function provideSettings(storage: Storage) {
  return new SettingsService({
    RefreshFlag: false, //Refresh
    RefreshTime: '3', // Tiempo de Refresh
    Url: 'https://api.boletosqubit.co/',
    TutorialFlag: false, //Presenta Tutorial
    Token: null, //Token
    VisitNumber: 1
  });
}
*/

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, 
            CommonModule,
            FormsModule, 
            ReactiveFormsModule,
            HttpClientModule,
            IonicModule.forRoot(), 
            AppRoutingModule, ServiceWorkerModule.register('/ngsw-worker.js', {
  enabled: environment.production,
  // Register the ServiceWorker as soon as the application is stable
  // or after 30 seconds (whichever comes first).
  registrationStrategy: 'registerWhenStable:30000'
}),             
            //FormsModule,
            //ReactiveFormsModule
          ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA],
  providers: [
              HttpService,
              AuthService,
              AuthGuard,
              //SettingsService,
              UtilService,
              //{ provide: SettingsService, useFactory: provideSettings, deps: [Storage] },
              { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
            ],
  bootstrap: [AppComponent],
})
export class AppModule {}
//https://ionicframework.com/blog/start-build-deploy-your-first-capacitor-pwa-with-ionic/
