import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { Storage } from '@capacitor/storage';
import { HttpService } from '../services/connection/httpservices.service';
import { SettingsService } from '../services/settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  response: Observable<any>;
  //Default User Values
  _defaults_user : any = {
    status: "No Auth",
    message: "No Connected",
    code : 0,
    data :[
            {
               USERNAME:"Nobody is connected",
               USERSEX : 0
            }
          ],    
    password : '00000'
  };  

  public _user: any= {
    status: "No Auth",
    message: "No Connected",
    code : 0,
    data :[
            {
               USERNAME:"Nobody is connected"
            }
          ],    
    password : '00000'
  };

  token : any;
  private readonly CURRENT_USER_KEY = '_settings_current_user';
  private readonly CURRENT_USER_AUTH = '_settings_current_user_auth';
  //public storage: Storage;
  constructor(private httpservice : HttpService) 
  { 

  }

  async load() {
    console.log("Cargando Autenticacion");
    const ret = await Storage.get({ key: this.CURRENT_USER_KEY });
    console.log("Usuario:");
    console.log(JSON.parse(ret.value));
    return Storage.get({ key: this.CURRENT_USER_KEY }).then((value) => {
      if (JSON.parse(ret.value) != null) {
        console.log(this.CURRENT_USER_KEY);
        //this.settings = value;
        
        console.log("Entre por el SI");
        this._loggedIn(value) ;
        //console.log (this._user);
      } else {
        console.log("No está utenticado");

      }
    });
  }
  
  
  /**
   * Send a POST request to our login endpoint with the data
   * the user entered on the form.
   */
   login(accountInfo: any) :any{
    let params:any;
    params = {
                   key: "login",
                   walletCelular : accountInfo.login__LOGIN,
                   password :accountInfo.login__PASSWORD,
                   idDispositivo:accountInfo.login__DEVICE
                  };               
     //console.log(params);
     this.response = this.httpservice.sendRequest(params);
     this.response.subscribe((resp)=>{
        console.log(resp.status);
        if (resp.status == "success") {
            //this._loggedIn(resp);
            console.log("Auth Ok");
            this._user=resp;
            Storage.set({key:this.CURRENT_USER_KEY,value:this._user});   
            Storage.set({key : this.CURRENT_USER_AUTH, value:params});  
            //this._defaults_user = params; 
            //return resp;
        }
        else {
           console.log("Invalid User");
           //return resp;
        }
                   
     },(Error) =>{
                 console.error('ERROR', Error);
                 
               }

     )

     return this.response;

  }  

  /**
   * Process a login/signup response to store user data
   */
   _loggedIn(resp) {
    this._user = resp;
  }


  getValue(key: string) {
    return Storage.get({key:this.CURRENT_USER_KEY})
      .then(user => {
        //console.log(user[key]);
        return user[key];
      });
  }

  getCurrentUser() {
     Storage.get({key:this.CURRENT_USER_KEY})
      .then(user => {
        return user;
      }, err=>{
          
          return new Promise ( resolve => {
              this._loggedIn(this._user);
              resolve( this._user);
            }     
     
           )    // Fin Promise
             
      });
  }  
  
 removeUser(){

       
      /**/
      Storage.remove({key:this.CURRENT_USER_KEY}).then(()=>{
       console.log('User- removed ');
     });

      Storage.set({key:this.CURRENT_USER_KEY,value:this._defaults_user});
      console.log('User-Updated ');

      Storage.remove({key:this.CURRENT_USER_AUTH}).then(()=>{
       console.log('User-Auth removed ');
     });       
   }  

 get getUser() {
   return this._user;
 }   

 get getAuth() {
     return this._defaults_user;
 } 

 removeAuth(){

      Storage.remove({key:this.CURRENT_USER_AUTH}).then(()=>{
       console.log('User-Auth removed ');
     });
 }   
}
