// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mp:{

    publickey:"",
    access_token:"",    

    clientidProd: "",
    clientsecredProd:""       
  },
  wp:{
    publicKey: "",
    prodkey  : ""
  },
  qb:{
    urlProd:"https://api.boletosqubit.co/prod/Applications/services/",
    version:"3.0.11"
  }  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
