import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { Storage } from '@capacitor/storage';
import { HttpService } from '../connection/httpservices.service';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public response: Observable<any>;
  public _islogged:boolean=false;
  _defaults_user : any = {
    status: "No Auth",
    message: "No Connected",
    code : 0,
    data :[
            {
               USERNAME:"Nobody is connected",
               USERSEX : 0
            }
          ],    
    password : '00000'
  };  

  /*
  public _user: any= {
    status: "No Auth",
    message: "No Connected",
    code : 0,
    data :[
            {
               USERNAME:"Nobody is connected"
            }
          ],    
    password : '00000'
  };
  */
  public _user: any=null;
  token : any;
  private readonly CURRENT_USER_KEY = '_settings_current_user';
  private readonly CURRENT_USER_AUTH = '_settings_current_user_auth';
  constructor(private httpservice : HttpService) 
  { 

  }

   /**
  * Qubit Labs All Rights Reserved (C) Enero 2022
  * Author :Jose Fernando Mendoza 
  * Function : Cara los datos de usuario que estan en localStore
  * Params : accountInfo Json datos de usuario
  **/
  async load() {
    //console.log("Cargando Autenticacion");
    const ret = await Storage.get({ key: this.CURRENT_USER_KEY });
    if (this.isJson(ret.value))
    //if (ret.value !='[object Object]' )
    {
      
      if (JSON.parse(ret.value) != null) {

        this._loggedIn(ret.value) ;
        this._islogged=true;

      } else {
        this._loggedIn(null) ;
        //console.log("No está utenticado 1");

      }
    }
    else
    {
      this._loggedIn(null) ;
      //console.log("No está utenticado 2");
    }  

  }

  isJson(item) {
    item = typeof item !== "string"
        ? JSON.stringify(item)
        : item;

    try {
        item = JSON.parse(item);
    } catch (e) {
        return false;
    }

    if (typeof item === "object" && item !== null) {
        return true;
    }

    return false;
}
    
  /**
  * Qubit Labs All Rights Reserved (C) 2022
  * Author :Jose Fernando Mendoza Enero 2022
  * Function : Autenticacion en el sistema
  * Params : accountInfo Json datos de usuario
  **/
   login(accountInfo: any) :any{
    let params:any;
    params = {
                   action: "auth",
                   walletCelular : accountInfo.login__LOGIN,
                   password :accountInfo.login__PASSWORD,
                   idDispositivo:accountInfo.login__DEVICE
                  };               

     return this.response = this.httpservice.sendRequest(params);

  }  //Fin

  /**
  * Qubit Labs All Rights Reserved (C) 2022
  * Author :Jose Fernando Mendoza
  * Function : Genera Token de Activacion
  * Params : accountInfo Json datos de usuario
  **/
  activacionCuenta(accountInfo: any) :any{
    let params:any;
    params = {
                   action: "activacionCuenta",
                   walletCelular : accountInfo.login__LOGIN,
                   idDispositivo:accountInfo.login__DEVICE
                  };               
     return this.response = this.httpservice.sendRequest(params);

  } //Fin
  
  
  /**
  * Qubit Labs All Rights Reserved (C) Enero 2022
  * Author :Jose Fernando Mendoza
  * Function : Genera token para recuperar la contraseña
  * Params : accountInfo Json datos de usuario
  **/
  recuperarPassword(accountInfo: any) :any{
    let params:any;
    params = {
                   action: "generatoken",
                   walletCelular : accountInfo.login__LOGIN,
                   cambio:accountInfo.login__CAMBIO
                  };               
    return this.response = this.httpservice.sendRequest(params);

  }//Fin

  /**
  * Qubit Labs All Rights Reserved (C) Enero 2022
  * Author :Jose Fernando Mendoza
  * Function : Genera token para el cambio del celular
  * Params : accountInfo Json datos de usuario
  **/
  generaTokenCambio(accountInfo: any) :any{
    let params:any;
    params = {
                   action: "generatoken",
                   walletCelular : accountInfo.login__LOGIN,
                   cambio:accountInfo.login__CAMBIO

                  };               
     return this.response = this.httpservice.sendRequest(params);

  } //Fin 
  
  /**
  * Qubit Labs All Rights Reserved (C) Enero 2022
  * Author :Jose Fernando Mendoza
  * Function : Consulta la existencia de un Token previamente generado
  * Params : accountInfo Json datos de usuario
  **/
  consultaToken(accountInfo: any) :any{
    let params:any;
    params = {
                   action: "consultaToken",
                   token: accountInfo.login__TOKEN,
                   walletCelular : accountInfo.login__LOGIN,
                   cambio:accountInfo.login__CAMBIO,
                   idDispositivo: accountInfo.login__DEVICE
                  };

     return this.response = this.httpservice.sendRequest(params);

  }//Fin

  /**
  * Qubit Labs All Rights Reserved (C) Enero 2022
  * Author :Jose Fernando Mendoza
  * Function : Consulta la existencia de un Token previamente generado
  * Params : accountInfo Json datos de usuario
  **/
   changeUserData(usuarioid,data,campo) :any{
    let params:any;
    params = {
                   action: "changeUserData",
                   usuarioid: usuarioid,
                   campo : campo,
                   data:data
                   
                  };

     return this.response = this.httpservice.sendRequest(params);

  }//Fin

  

    /**
  * Qubit Labs All Rights Reserved (C) Enero 2022
  * Author :Jose Fernando Mendoza
  * Function : Consulta la existencia de un Token previamente generado
  * Params : accountInfo Json datos de usuario
  **/
     generaDispositivoId(accountInfo: any) :any{
      let params:any;
      params = {
                     action: "generaDispositivoId",
                     walletCelular : accountInfo.login__LOGIN,
                    };
  
       return this.response = this.httpservice.sendRequest(params);
  
    }//Fin

  /**
  * Qubit Labs All Rights Reserved (C) Enero 2022
  * Author :Jose Fernando Mendoza
  * Function : Registra un usuario
  * Params : accountInfo Json datos de usuario
  **/
  crearUsuario(accountInfo: any) :any{  
    let params:any;
    params = {
                   action: "registro",
                   nombre:accountInfo.login__NOMBRE,
                   password: accountInfo.login__PASSWORD,
                   email: accountInfo.login__EMAIL,
                   walletCelular : accountInfo.login__LOGIN,
                   pais:accountInfo.login__PAIS,
                   taquilla:accountInfo.login_TAQUILLA
                  };        
    this.response = this.httpservice.sendRequest(params);                    
    return this.response; 
  }//Fin

  /**
  * Qubit Labs All Rights Reserved (C) Enero 2022
  * Author :Jose Fernando Mendoza
  * Function : Actualiza el password de un usuario
  * Params : accountInfo Json datos de usuario
  **/
  updatePassword(accountInfo: any) :any{  
    let params:any;
    params = {
                   action: "updatePassword",
                   password: accountInfo.login__PASSWORD,
                   walletCelular : accountInfo.login__LOGIN
                  };               
     return this.response = this.httpservice.sendRequest(params);
  }//Fin

    /**
  * Qubit Labs All Rights Reserved (C) Enero 2022
  * Author :Jose Fernando Mendoza
  * Function : Actualiza el password de un usuario
  * Params : accountInfo Json datos de usuario
  **/
     getFile(fileName: any) :any{  
      let params:any;
      params = {
                     action: "getFile",
                     fileName: fileName,
                    };               
       return this.response = this.httpservice.sendRequest(params);

  
    }//Fin

    /**
  * Qubit Labs All Rights Reserved (C) Enero 2022
  * Author :Jose Fernando Mendoza
  * Function : Guarda el usuario en localStore
  * Params : accountInfo Json datos de usuario
  **/  
     setAll(val: any) {
       this._loggedIn(val);
      return Storage.set({key:this.CURRENT_USER_KEY, value:JSON.stringify(val)});
    }


  /**
  * Qubit Labs All Rights Reserved (C) Enero 2022
  * Author :Jose Fernando Mendoza
  * Function : asigna el usuario autenticado
  * Params : accountInfo Json datos de usuario
  **/
   _loggedIn(resp) {
    this._user = resp;
  }

  /**
  * Qubit Labs All Rights Reserved (C) Enero 2022
  * Author :Jose Fernando Mendoza
  * Function : retorna un atributo del objeto Json de usuario almacenado en localStore
  * Params : accountInfo Json datos de usuario
  **/
  getValue(key: string) {
    return Storage.get({key:this.CURRENT_USER_KEY})
      .then(user => {

        return user[key];
      });
  } //Fin

  /**
  * Qubit Labs All Rights Reserved (C) Enero 2022
  * Author :Jose Fernando Mendoza
  * Function : Consulta los datos del usuario almacenado en localStore
  * Params : accountInfo Json datos de usuario
  **/
  async getCurrentUser() {

      const user  = await Storage.get({ key: this.CURRENT_USER_KEY});
      //console.log(user);
      if (this.isJson(user.value))
      {
        if (JSON.parse(user.value) != null) {
          return JSON.parse(user.value); //value.value[key];
        } 
        else 
         return null; 
      }

      return null; 
      /*
      if (user.value !='[object Object]' || user.value != null)
        return JSON.parse(user.value); //value.value[key]; 
      else
        return null;
        */       
  } //Fin 

  /**
  * Qubit Labs All Rights Reserved (C) Enero 2022
  * Author :Jose Fernando Mendoza
  * Function : Eliminia los datos del usuario que estan en localStore
  * Params : accountInfo Json datos de usuario
  **/
  removeUser(){

       
      /**/
      Storage.remove({key:this.CURRENT_USER_KEY}).then(()=>{
       console.log('User- removed ');
     });
      this._user=null;
      this.setisLogin(false);


      Storage.set({key:this.CURRENT_USER_KEY,value:""});
      console.log('User-Updated ');
 
  } //Fin 

  /**
  * Qubit Labs All Rights Reserved (C) Enero 2022
  * Author :Jose Fernando Mendoza
  * Function : Retorna los datos del usuario
  * Params : accountInfo Json datos de usuario
  **/   
  get getUser() {
    //console.log(this._user);
   return this._user;
  }   

  get getAuth() {
     return this._defaults_user;
  } //Fin

  setisLogin(value:boolean)
  {
    this._islogged=value;
  }
  
  get getisLogin()
  {
    return this._islogged;
  }

}
