import { Injectable } from '@angular/core';

import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
//import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';

import { catchError, tap } from 'rxjs/operators';

//import { AuthService } from '../Auth/auth.service';

import { Storage } from '@capacitor/storage';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private readonly CURRENT_USER_KEY = '_settings_current_user';
  public _user : any;
  constructor(private router: Router
              ) { } 
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    console.log("AuthGuard")
    return new Promise((resolve, reject) => {
    
    return Storage.get({key:this.CURRENT_USER_KEY})
      .then(user => {
        if (user.value !='[object Object]')
        {
             resolve(true);
        }
        resolve(false);
      }).catch(error=>{
              console.log(error);
              this.router.navigate(['/login']);
              resolve(false); 
      });



    });

  }
}