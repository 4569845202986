import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root' 
})
export class SettingsService {
	private SETTINGS_KEY: string = '_settings';

  settings: any;

  //_defaults: any;
  _defaults : any = {
    RefreshFlag: '1', //Refresh
    RefreshTime: '3', // Tiempo de Refresh
    Url: environment.qb.urlProd,
    TutorialFlag: false, //Presenta Tutorial
    deviceId: 0, //Token
    VisitNumber: 1,
    Environment:"QA"

  };

  _readyPromise: Promise<any>;

  constructor() {
      //if (defaults == null)
        //defaults = this._defaults
      //this._defaults = defaults;
      this.load();
      
  }
  /**
  * Qubit Labs All Rights Reserved (C) Enero 2022
  * Author :Jose Fernando Mendoza
  * Function : Carga los Settings del localStorage
  * Params : accountInfo Json datos de usuario
  **/
  async load() {
    const ret = await Storage.get({ key: this.SETTINGS_KEY });
   
    if (ret.value  != null) {
        this.settings = JSON.parse(ret.value);
        
        return this._mergeDefaults(this._defaults);
      } else {

      	
        return this.setAll(this._defaults).then((val) => {
          this.settings = JSON.stringify(this._defaults); //val;
        })
      }
  }

  /**
  * Qubit Labs All Rights Reserved (C) Enero 2022
  * Author :Jose Fernando Mendoza
  * Function : Asigna lo Settings por defecto
  * Params : accountInfo Json datos de usuario
  **/  
  _mergeDefaults(defaults: any) {
    for (let k in defaults) {
      if (!(k in this.settings)) {
        this.settings[k] = defaults[k];
      }
    }
    return this.setAll(this.settings);
  } //Fin

  /**
  * Qubit Labs All Rights Reserved (C) Enero 2022
  * Author :Jose Fernando Mendoza
  * Function : Asigna/Mezcla o modifica la variable Settings
  * Params : accountInfo Json datos de usuario
  **/  
  merge(settings: any) {
    for (let k in settings) {
      this.settings[k] = settings[k];
    }
    return this.save();
  }//Fin

  /**
  * Qubit Labs All Rights Reserved (C) Enero 2022
  * Author :Jose Fernando Mendoza
  * Function : Acctualiza en localStore un atributo de los Settingsen localStorage
  * Params : accountInfo Json datos de usuario
  **/  
  setValue(key: string, value: any) {
    this.settings[key] = value;
    return Storage.set({key:this.SETTINGS_KEY,value: JSON.stringify(this.settings)});
  }//Fin

  /**
  * Qubit Labs All Rights Reserved (C) Enero 2022
  * Author :Jose Fernando Mendoza
  * Function : Actualiza todos los settings de localStore
  * Params : accountInfo Json datos de usuario
  **/  
  setAll(val: any) {
    return Storage.set({key:this.SETTINGS_KEY, value:JSON.stringify(val)});
  }

  /**
  * Qubit Labs All Rights Reserved (C) Enero 2022
  * Author :Jose Fernando Mendoza
  * Function : Consulta un atributo de Setting que estan en localStore
  * Params : accountInfo Json datos de usuario
  **/  
  async getValue(key: string) {

      const value  = await Storage.get({ key: this.SETTINGS_KEY});
      return JSON.parse(value.value)[key]; 
  }//Fin

  /**
  * Qubit Labs All Rights Reserved (C) Enero 2022
  * Author :Jose Fernando Mendoza
  * Function : Gurarda los Settings en localStore
  * Params : accountInfo Json datos de usuario
  **/
  save() {
    return this.setAll(this.settings);
  }//Fin

  /**
  * Qubit Labs All Rights Reserved (C) Enero 2022
  * Author :Jose Fernando Mendoza
  * Function : Retorna todos los Settings previamente cargados
  * Params : accountInfo Json datos de usuario
  **/  
  get allSettings() {
    return this.settings;
  }//Fin
}