import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { MenuController, Platform, ToastController } from '@ionic/angular';
import { Observable} from 'rxjs';

import { AuthService } from './services/auth/auth.service'
//import { Plugin } from '@capacitor/core'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public  _user : any;
  public  userName :any;
  public  userID :any;  
  public  loggedIn = false;
  public  dark = false;
  public  storage :Storage;
  public  nombre:any;
  public  email:any;
   
  account: { login__LOGIN: string, login__PASSWORD: string, login__DEVICE : string } = {
    //login: 'fmendoza06@gmail.com',
    ///login: '16457542',
    ///password: '16457542',
    login__LOGIN: '3100000000',
    login__PASSWORD: 'F12345',     
    login__DEVICE:'0e3668d3482a0cd5',

 };

  constructor(
    private router: Router,
    private menu: MenuController,
    private platform: Platform,
    private toastCtrl: ToastController,
    private user:AuthService,

  ) {
    let response: Observable<any>;
    this.initializeApp();
    //this.checkEvents();

    this.user.load(); //Cargo el Usuario 
    //this._user= this.user.getUser;
    //user.removeUser();
    user.getCurrentUser().then((res)=>{
      if (res != null)
      {
        //console.log(res.data.usuanomb);
        this._user=res.data;
        this.nombre = res.data.usuanomb;
        this.email = res.data.usuaemai;
      }
      else
      {
        //console.log(res.data.nombre);
        this._user=null;
        this.nombre = "";
        this.email = "";        
      }
    });    
    //console.log(this._user);

    //response = user.login(this.account);
    //console.log(response);
    
  }

  async OnInit() {
  }  

  initializeApp() {
    if (Capacitor.isNativePlatform()) {
      /* To make sure we provide the fastest app loading experience 
          for our users, hide the splash screen automatically 
          when the app is ready to be used:
          
          https://capacitorjs.com/docs/apis/splash-screen#hiding-the-splash-screen
      */
      SplashScreen.hide();
      console.log(Capacitor.getPlatform());

    }
  }







}
