import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpRequest,HttpParams } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';  
import { Observable, of} from 'rxjs';
import { SettingsService } from '../settings/settings.service';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class HttpService {
    data       :    any;
    public     baseURI    :    string = environment.qb.urlProd; 
    public Settings = new SettingsService();
    constructor(public http : HttpClient){
        console.log('Qubit Team.');  

        
    }//END Constructor.

   /**
   * QlickBOx Solutions All Rights Reserved (C) 2022
   * Author :Jose Fernando Mendoza
   * Function : Execute get http protocol
   * Params : @parameters   : json params
   *          
   **/      
    sendRequest(parameters?: any) {
        // Support easy query params for GET requests
         let httpHeader = {

            headers: new HttpHeaders({ 'Content-Type': 'text/plain',
                                       'Authorization': 'MzRVU1BWVUFURThLWDE4MElDTFVUMDNDTzpQSHozZitnMzNiNFpHc1R3dEtOQ2h0NzhBejNpSjdwWTIwREo5N0R2L1g4' })
          };

          var headers = new Headers();
          headers.append("Accept", 'application/json');
          headers.append('Content-Type', 'text/plain' );
          //const requestOptions = new RequestOptions({ headers: headers });          
    
          let params:any;
          params="?";
          let p = new HttpParams(parameters);
          for (let k in parameters) {
           // p.set(k, parameters[k]);
            //console.log(k);
            params+=k+"="+parameters[k]+"&";
          }    
        
        //console.log(parameters); 
        //console.log(this.baseURI); 


 
        return this.http.post(this.baseURI,parameters,httpHeader)                 
        .pipe(map((response: Response) => response), 
        catchError((error: any) => throwError(new Error(error.message)))
        
         ); 
                 
    }//Fin
}   