import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
 

  /* , canActivate: [AuthGuard] */
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then(m => m.TutorialModule)
  }, 

  { path: '', 
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },  
   
  { path: 'login', 
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },    
  { path: 'login2', 
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  }, 
  { 
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },

  
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'register2',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },  
  {
    path: 'activate',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'recover',
    loadChildren: () => import('./pages/recover/recover.module').then( m => m.RecoverPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'valitoken/:wallet',
    loadChildren: () => import('./pages/valitoken/valitoken.module').then( m => m.ValitokenPageModule)
  },
  {
    path: 'changepass/:wallet',
    loadChildren: () => import('./pages/changepass/changepass.module').then( m => m.ChangepassPageModule)
  },
  {
    path: 'activate/:wallet',
    loadChildren: () => import('./pages/activate/activate.module').then( m => m.ActivatePageModule)
  },
  {
    path: 'changedevice/:wallet',
    loadChildren: () => import('./pages/changedevice/changedevice.module').then( m => m.ChangedevicePageModule)
  },
  {
    path: 'condiciones',
    loadChildren: () => import('./pages/condiciones/condiciones.module').then( m => m.CondicionesPageModule)
  },
  {
    path: 'purchase/:localidad',
    loadChildren: () => import('./pages/purchase/purchase.module').then( m => m.PurchasePageModule)
  },
  {
    path: 'edituser2',
    loadChildren: () => import('./pages/edituser/edituser.module').then( m => m.EdituserPageModule)
  },
  {
    path: 'checkout/:metodo',
    loadChildren: () => import('./pages/checkout/checkout.module').then( m => m.CheckoutPageModule)
  },
  {
    path: 'mytickets',
    loadChildren: () => import('./pages/mytickets/mytickets.module').then( m => m.MyticketsPageModule)
  },
  {
    path: 'transferticket',
    loadChildren: () => import('./pages/transferticket/transferticket.module').then( m => m.TransferticketPageModule)
  },
  {
    path: 'ticketsreader',
    loadChildren: () => import('./pages/ticketsreader/ticketsreader.module').then( m => m.TicketsreaderPageModule)
  },
  {
    path: 'coderider',
    loadChildren: () => import('./pages/coderider/coderider.module').then( m => m.CoderiderPageModule)
  },
 
  
 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
